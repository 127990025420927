import * as React from 'react';
import {DateTime as luxon} from 'luxon';
import {orderBy, cloneDeep, startCase} from 'lodash-es';

import {
  Box,
  Button,
  Cluster,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  FormItem,
  Grid,
  Help,
  Icon,
  ITableCol,
  Paragraph,
  Popover,
  Select,
  Spinner,
  Stack,
  Table,
  TagList,
  Template,
  TextInput,
  TIcons,
  Toast,
  Toggle,
} from '@pluto-tv/assemble';

import {TableActions} from 'components/tableActions';
import {INestedSeriesProps} from '../nestedPropsInterface';
import {useFindQuery as useFindPartnersQuery} from 'features/partners/partnersApi';
import {useUserRegions} from 'helpers/useUserRegions';

import CrudError from 'components/crudError';
import CustomReferenceForm from 'components/custom-reference-form';

import {useSeriesPermissions} from '../../permissions/useSeriesPermissions';
import {ICustomReference} from 'models/series';

interface ICustomReferencePopover {
  add?: boolean;
  [key: number]: boolean;
}

interface ISettingsProps extends INestedSeriesProps {
  dirtyFields: any;
}

const hasCustomReferenceType = (customReferenceTypes: ICustomReference[], type: string) => {
  return Boolean(customReferenceTypes.find(customReferenceType => customReferenceType.type === type));
};

export default ({
  form,
  model,
  setFields,
  onBlur,
  onChange,
  dirtyFields,
  pristineModel,
}: ISettingsProps): JSX.Element => {
  const {editPermission, canEditOO, canEditPartner} = useSeriesPermissions(model);

  const {territories, isFetching: isUserRegionsFetching, isError: isUserRegionsError} = useUserRegions();
  const {data: partners, isFetching: isFetchingPartners, isError: isErrorPartners} = useFindPartnersQuery();

  const [customReferencePopoverOpen, setCustomReferencePopoverOpen] = React.useState<ICustomReferencePopover>({});

  const hasDeliveryPartnerCustomRefSaved = hasCustomReferenceType(
    pristineModel.customReferences || [],
    'deliveryPartner',
  );

  const handleCustomReferenceClick = (icon: TIcons, index: number) => {
    if (icon === 'delete') {
      const cloned = cloneDeep(model.customReferences);

      if (!cloned) {
        return;
      }

      cloned.splice(index, 1);

      setFields({
        customReferences: cloned,
      });
    } else if (icon === 'edit') {
      setCustomReferencePopoverOpen({[index]: true});
    }
  };

  const handleCustomReferencesUpdate = (customReference: ICustomReference, index = -1) => {
    const cloned = cloneDeep(model.customReferences || []);

    const typeExists = cloned.find((ref, i) => ref.type === customReference.type && i !== index);

    if (!typeExists) {
      if (index >= 0) {
        cloned.splice(index, 1, customReference);
      } else {
        cloned.push(customReference);
      }
    } else {
      Toast.warning('The same Custom Reference type cannot be used more than once. Please update.');
    }

    setFields({
      customReferences: cloned,
    });

    setCustomReferencePopoverOpen({});
  };

  if (isUserRegionsError || isErrorPartners) {
    return <CrudError error='Error loading page data' />;
  }

  if (isUserRegionsFetching || isFetchingPartners) {
    return (
      <Box fullHeight={true}>
        <Spinner center={true} size='xlarge' />
      </Box>
    );
  }

  return (
    <form id='seriesSettingsForm'>
      <ContentBoxes layout='columns'>
        <ContentBoxColumn>
          <ContentBox title='Territories'>
            <Stack space='medium'>
              <FormItem {...form.regionFilter} permission={editPermission} onBlur={() => onBlur('regionFilter', false)}>
                <Select
                  placeholder='Please Select Territories'
                  onChange={value =>
                    setFields({
                      regionFilter: (value || [])?.map(v => v.value),
                    })
                  }
                  value={model.regionFilter?.map(d => ({label: d, value: d}))}
                  id='territories'
                  clearable={true}
                  searchable={true}
                  addAll={true}
                  multiselect={true}
                  predicate='value'
                  options={
                    orderBy(
                      (territories || []).map(t => ({label: t.name, value: t.id.toLowerCase()})),
                      'label',
                    ) || []
                  }
                />
              </FormItem>
            </Stack>
          </ContentBox>
          <ContentBox title='Availability Windows'>
            <Stack space='medium'>
              <Box marginTop='xsmallNegative'>
                <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
                  <Help state='info'>
                    All dates are on PDT. Start date will be set at 00:00:00, and end date at 23:59:59.
                  </Help>
                </Icon>
              </Box>
              {form.availabilityWindows?.helpText && (
                <Box marginTop='xsmallNegative'>
                  <Icon icon='warning' size='medium' iconAlign='center' space='xxsmall' color='error'>
                    <Help state='error'>{form.availabilityWindows?.helpText}</Help>
                  </Icon>
                </Box>
              )}
              <Stack space='xxxxlarge'>
                <Stack space='small'>
                  <Cluster align='center' space='medium'>
                    <Paragraph>Linear</Paragraph>
                    <Toggle
                      id='distributeAsLinear'
                      label='Yes'
                      permission={editPermission}
                      value={model.distributeAs?.linear}
                      onChange={val => setFields({distributeAs: {...model.distributeAs!, linear: val}})}
                    />
                  </Cluster>
                  <Table
                    id='availabilityWindowsLinear'
                    cols={[
                      {
                        label: 'Start Date',
                        transform: row =>
                          luxon
                            .fromISO(row.startDate as string)
                            .setZone('America/Los_Angeles')
                            .toFormat('yyyy-LL-dd, HH:mm:ss'),
                      },
                      {
                        label: 'End Date',
                        transform: row =>
                          luxon
                            .fromISO(row.endDate as string)
                            .setZone('America/Los_Angeles')
                            .toFormat('yyyy-LL-dd, HH:mm:ss'),
                      },
                      {
                        label: 'Screenings',
                        field: 'screenings',
                      },
                    ]}
                    rows={model.availabilityWindows?.linear || []}
                    emptyMsg='No Linear Availability Windows'
                  ></Table>
                </Stack>
                <Stack space='small'>
                  <Cluster align='center' space='medium'>
                    <Paragraph>AVOD</Paragraph>
                    <Toggle
                      id='distributeAsAVOD'
                      label='Yes'
                      value={model.distributeAs?.AVOD}
                      permission={editPermission}
                      onChange={val => setFields({distributeAs: {...model.distributeAs!, AVOD: val}})}
                    />
                  </Cluster>
                  <Table
                    cols={[
                      {
                        label: 'Start Date',
                        transform: row =>
                          luxon
                            .fromISO(row.startDate as string)
                            .setZone('America/Los_Angeles')
                            .toFormat('yyyy-LL-dd, HH:mm:ss'),
                      },
                      {
                        label: 'End Date',
                        transform: row =>
                          luxon
                            .fromISO(row.endDate as string)
                            .setZone('America/Los_Angeles')
                            .toFormat('yyyy-LL-dd, HH:mm:ss'),
                      },
                      {
                        label: 'Screenings',
                        field: 'screenings',
                      },
                    ]}
                    emptyMsg='No AVOD Availability Windows'
                    rows={model.availabilityWindows?.AVOD || []}
                  ></Table>
                </Stack>
              </Stack>
            </Stack>
          </ContentBox>
        </ContentBoxColumn>
        <ContentBoxColumn>
          <ContentBox title='Identification'>
            <Stack space='medium'>
              <Grid rowGap='small' columnGap='xlarge'>
                <FormItem {...form.type} permission={editPermission}>
                  <Select
                    onChange={value => setFields({type: value.value})}
                    value={{label: model.type || '', value: model.type}}
                    id='type'
                    predicate='value'
                    placeholder='Select Type'
                    options={[
                      {label: 'Film', value: 'film'},
                      {label: 'TV', value: 'tv'},
                      {label: 'Web Original', value: 'web-original'},
                      {label: 'Music Video', value: 'music-video'},
                      {label: 'Live', value: 'live'},
                    ]}
                  />
                </FormItem>
                <FormItem
                  label='Pluto TV O&O'
                  helpText='If "Yes", the series belongs to PlutoTV O&O.'
                  helpTextColor='info'
                  child='Toggle'
                  permission={canEditOO !== canEditPartner || (!canEditOO && !canEditPartner) ? 'disabled' : 'enabled'}
                >
                  <Toggle
                    id='plutoTvOO'
                    label='Yes'
                    onChange={value => onChange('plutoTvOO', value)}
                    value={model.plutoTvOO}
                  />
                </FormItem>
              </Grid>
              <FormItem
                {...form.slug}
                onBlur={() => onBlur('slug')}
                permission={editPermission}
                state={form.slug?.state ? form.slug.state : dirtyFields.slug ? 'warning' : ''}
                helpText={
                  form.slug?.helpText
                    ? form.slug.helpText
                    : dirtyFields.slug
                    ? 'Changing the slug can break old references to this series. Proceed with caution.'
                    : ''
                }
              >
                <TextInput
                  onChange={value => onChange('slug', value)}
                  value={model.slug}
                  id='slug'
                  placeholder='Series Slug'
                />
              </FormItem>
              <FormItem {...form.tmsid} onBlur={() => onBlur('tmsid')} permission={editPermission}>
                <TextInput
                  onChange={value => onChange('tmsid', value)}
                  value={model.tmsid}
                  id='tmsId'
                  placeholder='TMS ID'
                />
              </FormItem>
            </Stack>
          </ContentBox>
          <ContentBox title='External Partner IDs'>
            <Table
              cols={[
                {
                  label: 'Key',
                  field: 'key',
                },
                {
                  label: 'ID',
                  field: 'value',
                },
              ]}
              rows={model.externalIds || []}
              emptyMsg='No IDs Available for this item'
            ></Table>
          </ContentBox>
          <ContentBox title='Configuration'>
            <Stack space='small'>
              <Table
                id='customReference'
                cols={[
                  {
                    label: 'Custom Reference Type',
                    transform: row => startCase(row.type),
                  },
                  {
                    label: 'Partner',
                    transform: row => (partners?.find(partner => partner.id === row.value.id) || {name: row}).name,
                  },
                  ...(canEditOO || canEditPartner
                    ? [
                        {
                          label: 'Actions',
                          colWidth: '6.25rem',
                          transform: (row, _col, index) => {
                            const canEditCustomRef =
                              row.type !== 'deliveryPartner' || !hasDeliveryPartnerCustomRefSaved;
                            return (
                              <Cluster>
                                <TableActions
                                  row={row}
                                  icons={[]}
                                  deleteOption={canEditCustomRef}
                                  displayField='name'
                                  altTitle='custom reference type'
                                  onClick={(row, icon) => handleCustomReferenceClick(icon, index)}
                                >
                                  <Popover
                                    appendToBody={true}
                                    manualTrigger={true}
                                    visible={customReferencePopoverOpen[index]}
                                    onClickOutside={() => setCustomReferencePopoverOpen({})}
                                  >
                                    {canEditCustomRef && (
                                      <Template label='trigger'>
                                        <Icon
                                          space='small'
                                          icon='edit'
                                          onClick={() => handleCustomReferenceClick('edit', index)}
                                        />
                                      </Template>
                                    )}
                                    <Template label='popover'>
                                      <CustomReferenceForm
                                        partners={partners || []}
                                        visible={customReferencePopoverOpen[index]}
                                        onCancel={() => setCustomReferencePopoverOpen({})}
                                        value={{
                                          id: row.id,
                                          type: row.type,
                                          value: row.value.id,
                                        }}
                                        onSave={val =>
                                          handleCustomReferencesUpdate(
                                            {
                                              id: val.id,
                                              type: val.type,
                                              value: {
                                                id: val.value,
                                                name:
                                                  partners?.find(partner => partner.id === val.value)?.name ||
                                                  val.value,
                                              },
                                            },
                                            index,
                                          )
                                        }
                                      />
                                    </Template>
                                  </Popover>
                                </TableActions>
                              </Cluster>
                            );
                          },
                        } as ITableCol<ICustomReference>,
                      ]
                    : []),
                ]}
                rows={model.customReferences || []}
              ></Table>
              <Cluster justify='space-between'>
                <div></div>
                {(canEditOO || canEditPartner) && (
                  <Popover
                    manualTrigger={true}
                    visible={customReferencePopoverOpen.add}
                    onClickOutside={() => setCustomReferencePopoverOpen({})}
                  >
                    <Template label='trigger'>
                      <Button type='primary' onClick={() => setCustomReferencePopoverOpen({add: true})}>
                        + Add
                      </Button>
                    </Template>
                    <Template label='popover'>
                      <CustomReferenceForm
                        partners={partners || []}
                        isNew={true}
                        visible={customReferencePopoverOpen.add}
                        value={{}}
                        onSave={val =>
                          handleCustomReferencesUpdate({
                            id: val.id,
                            type: val.type,
                            value: {
                              id: val.value,
                              name: partners?.find(partner => partner.id === val.value)?.name || val.value,
                            },
                          })
                        }
                        onCancel={() => setCustomReferencePopoverOpen({})}
                      />
                    </Template>
                  </Popover>
                )}
              </Cluster>
            </Stack>
          </ContentBox>
          <ContentBox title='Tags'>
            <Stack space='medium'>
              <FormItem {...form.tags} permission={editPermission}>
                <TagList
                  value={model.tags}
                  onChange={value =>
                    setFields({tags: orderBy([...new Set(value || [])] as string[], tag => tag.toLowerCase())})
                  }
                  placeholder='Tags'
                  id='tags'
                />
              </FormItem>
              <FormItem {...form.seoAliases} permission={editPermission}>
                <TagList
                  value={model.seoAliases}
                  onChange={value =>
                    setFields({
                      seoAliases: orderBy([...new Set(value || [])] as string[], tag => tag.toLowerCase()),
                    })
                  }
                  id='seoAliases'
                  placeholder='SEO Aliases'
                />
              </FormItem>
            </Stack>
          </ContentBox>
        </ContentBoxColumn>
      </ContentBoxes>
    </form>
  );
};
