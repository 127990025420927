import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {RootState} from 'app/store';
import {IVodCategoryEntryExpanded} from 'models/vodCategoryEntry';
import {ICapstanFilters} from 'views/programming/vodCollections/edit/capstan/models/capstan';

interface IListVodEntryPayload {
  metadata: {
    offset: number;
    limit: number;
    totalCount: number;
  };
  data: {
    documents: IVodCategoryEntryExpanded[];
    sum_durations: number;
  };
}

export const capstanEntriesApi = createApi({
  reducerPath: 'capstanEntriesApi',
  tagTypes: ['CapstanEntries'],
  baseQuery: fetchBaseQuery({
    baseUrl: '/v1/',
    prepareHeaders(headers, {getState}) {
      const token = (getState() as RootState).user.jwt;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: builder => ({
    capstan: builder.query<IListVodEntryPayload, ICapstanFilters>({
      query: capstanQuery => ({
        url: 'amlg/preview',
        body: capstanQuery,
        method: 'POST',
      }),
      providesTags: ['CapstanEntries'],
    }),
  }),
});

export const {useCapstanQuery, useLazyCapstanQuery} = capstanEntriesApi;
