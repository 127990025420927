import * as React from 'react';
import {
  Box,
  Cluster,
  Grid,
  Heading,
  Help,
  ISimpleScheduleEntry,
  Icon,
  ImageWrapper,
  Label,
  Paragraph,
  Stack,
  Table,
  secondsToHms,
} from '@pluto-tv/assemble';
import {IAgeRange, IEpisodeProgramDetails} from 'models/episodes';
import Warnings from 'views/programming/channel/edit/program/components/Warnings';
import {formatDateWithTimezone} from 'utils/dateUtils';

import {getItemState} from 'views/programming/channel/utils';
import {IChannelCatalogItem} from 'models/channelCatalog';

import {isSimpleScheduleEntry} from 'views/programming/channel/edit/program/components/EpisodesDetails';
import {useAppPermissions} from 'app/permissions';
import {rewriteImageUrl} from 'utils/imageUtils';

const defaultPosterUrl = 'http://pluto.tv.s3.amazonaws.com/assets/images/default/vod.screenshot16_9-default.jpg';

interface SingleSelectDetailsProps {
  episode: IEpisodeProgramDetails;
  timelineEpisode: ISimpleScheduleEntry | IChannelCatalogItem;
  onRemove?: (id: string) => void;
}

const airDatesFormatString = 'yyyy-LL-dd h:mm a';

const renderValue = (value?: string) => {
  return value || 'N/A';
};

const getAgeRangeValue = (ageRange?: IAgeRange[]): string => {
  if (!ageRange) return '';
  return ageRange.map(range => range.displayName).join(' - ');
};

export default ({timelineEpisode, episode, onRemove}: SingleSelectDetailsProps): React.ReactElement | null => {
  const {ableTo} = useAppPermissions();
  const warnings = getItemState(episode as unknown as IChannelCatalogItem).stateMsgList;

  const availabilityWindowOptions = React.useMemo(() => {
    return (episode.availabilityWindows?.linear || [])
      .filter(window => {
        const today = Date.now();
        const todayWithTimezone = formatDateWithTimezone(new Date(today).toISOString());
        const todayTime = new Date(todayWithTimezone).getTime();

        const startDate = formatDateWithTimezone(window.startDate);
        const startTime = new Date(startDate).getTime();
        const endDate = formatDateWithTimezone(window.endDate);
        const endTime = new Date(endDate).getTime();

        return startTime >= todayTime || todayTime <= endTime;
      })
      .map(window => ({
        startDate: formatDateWithTimezone(window.startDate, 'yyyy-LL-dd, HH:mm:ss'),
        endDate: formatDateWithTimezone(window.endDate, 'yyyy-LL-dd, HH:mm:ss'),
      }));
  }, [episode.availabilityWindows?.linear]);

  return (
    <Stack space='xlarge'>
      <Stack space='xlarge'>
        <ImageWrapper
          backgroundColor='black'
          width='100%'
          height='186px'
          src={rewriteImageUrl(episode.screenshot16_9?.path || defaultPosterUrl)}
          alt={episode.screenshot16_9?.altText}
          title={episode.screenshot16_9?.title}
        />
      </Stack>
      <Cluster space='xlarge' align='center' justify='center'>
        <Heading level='h4'>
          <Icon
            linkTarget='_blank'
            href={`/series/${episode.series.id}/episodes/${episode.id}/details`}
            icon='open'
            color='primary'
            hoverColor='primaryLight'
            textColor='primary'
            space='xxsmall'
            textDecoration={false}
          >
            View Episode
          </Icon>
        </Heading>
        {onRemove && isSimpleScheduleEntry(timelineEpisode) && !timelineEpisode.locked && ableTo('CHANNEL_EPG_EDIT') && (
          <Heading level='h4'>
            <Icon
              icon='delete'
              color='delete'
              textColor='delete'
              hoverColor='deleteLight'
              space='xxsmall'
              onClick={() => {
                onRemove(timelineEpisode.id);
              }}
            >
              Remove Episode
            </Icon>
          </Heading>
        )}
      </Cluster>
      <Stack space='large'>
        <Box
          background='slate'
          borderTop={true}
          borderSize='0.125rem'
          borderColor='onyx'
          paddingY={{mobile: 'medium', wide: 'large'}}
          paddingX={{mobile: 'medium', wide: 'xlarge'}}
        >
          <Stack space='xlarge'>
            <Heading level='h3' color='secondary'>
              Information
            </Heading>
            <Grid gap='xxlarge' minimum='7.775rem'>
              <Stack space='large'>
                <Label>Episode Duration</Label>
                <Paragraph>{secondsToHms(episode.duration)}</Paragraph>
              </Stack>
              <Stack space='large'>
                <Label>Rating</Label>
                <Paragraph>{renderValue(episode.rating)}</Paragraph>
              </Stack>
              <Stack space='large'>
                <Label>Genre</Label>
                <Paragraph>{renderValue(episode.genre)}</Paragraph>
              </Stack>
              <Stack space='medium'>
                <Label>Sub Genre</Label>
                <Paragraph>{renderValue(episode.subGenre)}</Paragraph>
              </Stack>
              <Stack space='large'>
                <Label>Category</Label>
                <Paragraph>{renderValue(episode.category)}</Paragraph>
              </Stack>
              <Stack space='large'>
                <Label>Sub Category</Label>
                <Paragraph>{renderValue(episode.subCategory)}</Paragraph>
              </Stack>
              <Stack space='large'>
                <Label>Age Range</Label>
                <Paragraph>{renderValue(getAgeRangeValue(episode.ageRange))}</Paragraph>
              </Stack>
            </Grid>
          </Stack>
        </Box>
        <Box
          background='slate'
          borderTop={true}
          borderSize='0.125rem'
          borderColor='onyx'
          paddingY={{mobile: 'medium', wide: 'large'}}
          paddingX={{mobile: 'medium', wide: 'xlarge'}}
        >
          <Stack space='medium'>
            <Heading level='h3' color='secondary'>
              Availability Windows
            </Heading>
            <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
              <Help state='info'>
                All dates are on PDT. Start date will be set at 00:00:00, and end date at 23:59:59.
              </Help>
            </Icon>
            <Table
              cols={[
                {
                  label: 'Start Date',
                  field: 'startDate',
                },
                {
                  label: 'End Date',
                  field: 'endDate',
                },
              ]}
              rows={availabilityWindowOptions}
              emptyMsg='No Linear Availability Windows'
            ></Table>
          </Stack>
        </Box>
        <Box
          background='slate'
          borderTop={true}
          borderSize='0.125rem'
          borderColor='onyx'
          paddingY={{mobile: 'medium', wide: 'large'}}
          paddingX={{mobile: 'medium', wide: 'xlarge'}}
        >
          <Stack space='xxsmall'>
            <Heading level='h3' color='secondary'>
              Air Dates
            </Heading>
            <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
              <Help state='info'>All dates are on PDT.</Help>
            </Icon>
            <Table
              cols={[
                {
                  label: 'Last Air Date',
                  field: 'lastAirDate',
                },
                {
                  label: 'Next Air Date',
                  field: 'nextAirDate',
                },
              ]}
              rows={[
                {
                  lastAirDate: episode.lastAired
                    ? formatDateWithTimezone(episode.lastAired, airDatesFormatString)
                    : 'N/A',
                  nextAirDate: episode.nextTimeline?.start
                    ? formatDateWithTimezone(episode.nextTimeline?.start, airDatesFormatString)
                    : 'N/A',
                },
              ]}
            ></Table>
          </Stack>
        </Box>

        <Box
          background='slate'
          borderTop={true}
          borderSize='0.125rem'
          borderColor='onyx'
          paddingY={{mobile: 'medium', wide: 'large'}}
          paddingX={{mobile: 'medium', wide: 'xlarge'}}
        >
          <Stack space='large'>
            <Heading level='h3' color='secondary'>
              {`Warnings ${warnings.length ? `(${warnings.length})` : ''}`}
            </Heading>
            {warnings.length ? (
              <Warnings warnings={warnings} />
            ) : (
              <Cluster justify='start' align='center' space='xxlarge'>
                <Icon icon='check' space='xxxsmall' verticalAlign='bottom' lineHeight='0px' color='success'>
                  No Warnings
                </Icon>
              </Cluster>
            )}
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
};
