export const sortByOptions = [
  {value: 'rank', label: 'Rank'},
  {value: 'external_rating_full', label: 'IVA Score'},
  {value: 'release_dt', label: 'Release Date'},
  {value: 'year', label: 'Publishing Year'},
  {value: 'availability_start', label: 'Avail Start Date'},
  {value: 'plutotv_insert_dt', label: 'Date Created'},
  {value: 'plutotv_expire_dt', label: 'Date Expired'},
  {value: 'plutotv_title', label: 'Title'},
  {value: 'trending_score', label: 'Search Trending Rank'},
];

export const sortDirectionOptions = [
  {value: 'asc', label: 'Ascending'},
  {value: 'desc', label: 'Descending'},
];
