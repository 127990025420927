import * as React from 'react';
import {Box, Cluster, ContentBox, Dialog, Grid, Heading, Paragraph, Stack, Template} from '@pluto-tv/assemble';
import {TemplatesTable} from './TemplatesTable';
import mockedTemplatesData from './data/mockedTemplatesData.json';
import {IVodQueryTemplate} from './models';

export const QueryTemplatesDialog = ({
  isModalOpen,
  onClose,
}: {
  isModalOpen: boolean;
  onClose: () => void;
}): JSX.Element => {
  const data = mockedTemplatesData as IVodQueryTemplate[];
  const sample = `genres==genres and (available_dt<=@today or available_dt!=available_dt) and (release_dt<=@today or release_dt!=release_dt) and brand_slug.str.contains("smithsonian")==False and existing_enabled_home_page_carousel_ct==0 and (pplus_genres.str.contains("documentary) or genres.str.contains("Documentary))`;

  return (
    <Dialog isOpen={isModalOpen} onClose={onClose} width='70%' height='80%'>
      <Template label='header'>
        <Cluster>
          <Heading level='h2'>Query Templates</Heading>
          <p style={{marginLeft: '1.5rem', fontSize: '0.8rem'}}>{data.length} Items</p>
        </Cluster>
      </Template>
      <Template label='body'>
        <Box marginLeft='xlarge' marginBottom='large'>
          <Heading level='h3' color='secondary'>
            &quot;How To&quot; Creation Guide
          </Heading>
        </Box>
        <Stack>
          <Grid maxCols={2}>
            <ContentBox>
              <Stack space='medium'>
                <Paragraph color='secondary' size='small'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam luctus odio id malesuada dignissim.
                  Praesent eu metus et quam scelerisque volutpat. Aliquam condimentum mi turpis, aliquam ullamcorper
                  tortor rutrum id. Phasellus dictum sapien at nisl faucibus, consectetur hendrerit nisl rutrum.
                </Paragraph>
                <Paragraph color='secondary' size='small'>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam luctus odio id malesuada dignissim.
                  Praesent eu metus et quam scelerisque volutpat.
                </Paragraph>
                <Paragraph color='secondary' size='small'>
                  For a detailed how to:
                </Paragraph>
              </Stack>
              <Box marginTop='xxxxsmall'>
                <Paragraph size='small'>
                  <a href='#' target='_blank' style={{textDecoration: 'none'}}>
                    Capstan-AMLG&apos;s Carousel Creation Guide
                  </a>
                </Paragraph>
              </Box>
            </ContentBox>
            <ContentBox>
              <Stack space='medium'>
                <Paragraph color='secondary' size='small'>
                  Sample:
                </Paragraph>
                <Paragraph color='secondary' size='small'>
                  {sample}
                </Paragraph>
              </Stack>
            </ContentBox>
          </Grid>
          <TemplatesTable data={data} />
        </Stack>
      </Template>
    </Dialog>
  );
};
