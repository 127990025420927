import * as React from 'react';
import {Box, Button, Cluster, FormItem, Select, Stack, useValidateForm} from '@pluto-tv/assemble';
import {capstanSortingValidator} from 'views/programming/vodCollections/validators';
import {sortByOptions, sortDirectionOptions} from './sortOptions';
import {ICapstanSort} from './models/capstan';

export interface ISortFormProps {
  onCancel(): void;
  isNew?: boolean;
  value?: Partial<ICapstanSort>;
  onSave: (savedObj: ICapstanSort) => void;
  visible?: boolean;
}

const SORT_INIT: ICapstanSort = {
  criteria: '',
  direction: '',
};

export const CapstanSortForm = ({onCancel, isNew = false, value, onSave, visible}: ISortFormProps): any => {
  const {
    model,
    onBlur,
    form,
    setModel,
    state: formState,
    setFields,
  } = useValidateForm<ICapstanSort>(capstanSortingValidator);

  React.useEffect(() => {
    if (visible) {
      setModel(isNew ? SORT_INIT : value!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, value, isNew]);

  return (
    <Box padding='small' background='charcoal' width='260px'>
      <form id='seasonForm'>
        <Stack space='small'>
          <FormItem {...form.criteria} onBlur={() => onBlur('criteria', false)} required>
            <Select
              placeholder={'Choose Sort by'}
              options={sortByOptions}
              value={{
                label: sortByOptions.find(x => x.value === model.criteria)?.label || '',
                value: model.criteria,
              }}
              onChange={val => {
                setFields({
                  criteria: val.value,
                });
              }}
            />
          </FormItem>
          <FormItem {...form.direction} onBlur={() => onBlur('direction', false)} required>
            <Select
              placeholder={'Choose Sort Order'}
              options={sortDirectionOptions}
              value={{
                label: sortDirectionOptions.find(x => x.value === model.direction)?.label || '',
                value: model.direction,
              }}
              onChange={val => {
                setFields({
                  direction: val.value,
                });
              }}
            />
          </FormItem>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster>
              <Button ghost={true} onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type='primary'
                onClick={() => onSave(model as ICapstanSort)}
                state={
                  !formState.isValid ||
                  !formState.isDirty ||
                  model.criteria === undefined ||
                  model.direction === undefined
                    ? 'disabled'
                    : ''
                }
              >
                {isNew ? '+ Add' : 'Update'}
              </Button>
            </Cluster>
          </Cluster>
        </Stack>
      </form>
    </Box>
  );
};
