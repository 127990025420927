import {IValidatorField} from '@pluto-tv/assemble';

import {IVodCollection} from 'models/vodCollections';
import {ICapstanSort} from '../edit/capstan/models/capstan';

export const vodCollectionSearchValidator: IValidatorField<IVodCollection>[] = [
  {
    name: 'name',
    label: 'VOD Collection Name',
  },
  {
    name: 'activeRegion',
    label: 'Active Region',
  },
  {
    name: 'activeRegions',
    label: 'Active Region',
  },
  {
    name: 'devicesIncluded',
    label: 'Devices Included',
  },
  {
    name: 'devicesExcluded',
    label: 'Devices Excluded',
  },
  {
    name: 'enabled',
    label: 'Published',
  },
  {
    name: 'unpublished',
    label: 'Unpublished',
  },
  {
    name: 'archived',
    label: 'Archived',
  },
];

export const vodCollectionDeviceUpdateValidator: IValidatorField<IVodCollection>[] = [
  {
    name: 'distribution',
  },
];

export const vodCollectionCommonNameValidations: IValidatorField<IVodCollection>[] = [
  {
    name: 'name',
    label: 'Collection Name',
    required: true,
    validators: [
      (name: string): string | undefined => {
        if (!name || !name.trim().length) {
          return 'Collection Name is required';
        } else if (name && name.trim().length > 100) {
          return 'Collection Name must be less than 100 characters';
        }
      },
    ],
  },
  {
    name: 'displayName',
    label: 'Display Name',
    required: true,
    validators: [
      (displayName: string): string | undefined => {
        if (!displayName || !displayName.trim().length) {
          return 'Display Name is required';
        } else if (displayName && displayName.trim().length > 100) {
          return 'Display Name must be less than 100 characters';
        }
      },
    ],
  },
];

export const vodCollectionCloneDetails: IValidatorField<IVodCollection>[] = [
  ...vodCollectionCommonNameValidations,
  {
    name: 'categories',
    label: 'Main Categories',
  },
];

export const vodCollectionSimpleDetails: IValidatorField<IVodCollection>[] = [
  ...vodCollectionCommonNameValidations,
  {
    name: 'activeRegion',
    label: 'Active Region',
    required: true,
    validators: [
      (region: string): string | undefined => {
        if (!region) {
          return 'Active Region is required';
        }
      },
    ],
  },
];

export const vodCollectionCreateValidator: IValidatorField<IVodCollection>[] = [
  ...vodCollectionSimpleDetails,
  {
    name: 'categories',
    label: 'Main Categories',
    required: true,
    validators: [
      (categories: string[], model: Partial<IVodCollection>): string | undefined => {
        if (!model.activeRegion) {
          return 'Select an Active Region';
        } else if (!categories || !categories.length) {
          return 'Main Categories is required';
        }
      },
    ],
  },
];

export const vodCollectionDetailsValidator: IValidatorField<IVodCollection>[] = [
  ...vodCollectionSimpleDetails,
  {
    name: 'categories',
    label: 'Main Categories',
  },
  {
    name: 'description',
    label: 'Description',
    required: false,
    validators: [
      (description: string): string | undefined => {
        if (description?.length > 255) {
          return 'Description is limited up to 255 characters';
        }
      },
    ],
  },
  {
    name: 'kidsMode',
    label: 'Kids Mode',
  },
  {
    name: 'heroCarousel',
    label: 'Hero Carousel',
  },
  {
    name: 'plutoOfficeOnly',
    label: 'Office Only',
  },
  {
    name: 'intileChannel',
    label: 'Intile Channel',
  },
  {
    name: 'intileOrder',
    label: 'Intile Order',
  },
];

export const vodCollectionDistributionValidator: IValidatorField<IVodCollection>[] = [
  {
    name: 'regionFilter',
    label: 'Territories',
    validators: [
      (newValues: string[], model: Partial<IVodCollection>): string | undefined => {
        const field = model.regionFilter;
        if (
          (!newValues || !newValues.length) &&
          (!field?.exclude || !field?.exclude.length) &&
          (!field?.include || !field?.include.length)
        ) {
          return `Included Territories OR Excluded Territories is required`;
        }
      },
    ],
  },
  {
    name: 'distribution',
    label: 'Devices',
    validators: [
      (newValues: string[], model: Partial<IVodCollection>): string | undefined => {
        const field = model.distribution;
        if (
          (!newValues || !newValues.length) &&
          (!field?.exclude || !field?.exclude.length) &&
          (!field?.include || !field?.include.length)
        ) {
          return `Included Devices OR Excluded Devices is required`;
        }
      },
    ],
  },
  {
    name: 'customReferences',
    label: 'Custom References',
  },
];

export const vodCollectionProgramValidator: IValidatorField<IVodCollection>[] = [...vodCollectionSimpleDetails];

export const vodCollectionEditValidator: IValidatorField<IVodCollection>[] = [
  {
    name: 'enabled',
    label: 'Published',
    required: true,
  },
  {
    name: 'archived',
    label: 'Archived',
    required: true,
  },
];

export const vodCollectionArtworksValidator: IValidatorField<IVodCollection>[] = [{name: 'imageFeatured'}];

export const vodCollectionCapstanValidator: IValidatorField<IVodCollection>[] = [
  {
    name: 'capstanSyntax',
    label: 'Enter Syntax',
  },
];

export const capstanSortingValidator: IValidatorField<ICapstanSort>[] = [
  {
    name: 'criteria',
    label: 'Sort By',
  },
  {
    name: 'direction',
    label: 'Sort Order',
  },
];
